<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Faq Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6">
                    <v-select
                        v-model="faq.type"
                        :items="types"
                        menu-props="auto"
                        label="Type"
                        hide-details
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="faq.question"
                        label="Question"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <editor
                        :id="`answer_content`"
                        v-model="faq.answer"
                        :api-key="tinymceApiKey"
                        :init="editorInit"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn :loading="loading" class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import faqService from '@/services/faq'
    import Alert from '@/components/Alert'
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: 'FaqForm',
        components: { Alert, Editor },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                mode: null,
                googleApiHost: 'https://storage.googleapis.com',
                readOnly: false,
                clearable: true,
                loading: false,
                startDate: null,
                endDate: null,
                faq: {
                },
                editorInit: {
                    menubar: false,
                    plugins: [
                        'autoresize advlist autolink lists charmap print preview anchor',
                        'searchreplace visualblocks fullscreen',
                        'insertdatetime table paste code help wordcount link'
                    ],
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link unlink'
                },
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
                faqId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                types: ['general']
            }
        },
        async created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.faqId = this.$route.params.faqId
            if (this.faqId) {
                faqService.getFaq(this.faqId).then(resp => {
                    this.faq = resp.data
                })
            }
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return !this.faq.question || !this.faq.answer || !this.faq.type
            },
            onEdit() {
                this.$router.push(`/faqs/${this.faqId}/edit`)
            },
            createFaq() {
                faqService.createFaq(this.faq).then(faqResponse => {
                    if (faqResponse.id) {
                        this.faqId = faqResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/faqs`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editFaq() {
                faqService.updateFaq(this.faqId, this.faq).then(faqResponse => {
                    if (faqResponse.id) {
                        this.faqId = faqResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/faqs`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            async onSubmit() {
                this.loading = true
                if (this.mode === 'edit') {
                    this.editFaq()
                } else if (this.mode === 'create') {
                    this.createFaq()
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
